import moment from 'moment'

moment.locale('fr');

class ActivityTheme {
    constructor() {
        this.init();
    }

    init() {
        let container = $(document).find('.block-1-activities-month')

        container.on('click', '.block-select-theme .slide-item', function (e) {
            e.preventDefault();

            let items_block = container.find('.block-theme')
            let block_id = $(this).attr('data-active');
            let block_item = container.find('.block-theme[data-active="' + block_id + '"]')

            if (block_item.length > 0) {
                container.find('.block-select-theme .slide-item').removeClass('active');
                $(this).addClass('active');
                items_block.removeClass('active');
                block_item.addClass('active');
            }
        })
    }
}

export default ActivityTheme