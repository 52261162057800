class CourseAccommodationCheckbox {
    constructor() {
        this.init();
    }

    init() {
        let item = $(document).find('.accommodation-container .accommodation-group-head.switch-checkbox-item-parent input[type="radio"]');

        item.change(function () {
            if ($(this).is(':checked')) {
                let parent_class = $(this).parents('.accommodation-item-parent').attr('class');
                parent_class = parent_class.split(' ');
                let class_list = parent_class.map(function (class_item) {
                    if (class_item.trim() !== '' && class_item.trim() !== '.') {
                        return class_item === 'active' ? class_item : '.' + class_item;
                    }
                });
                let item_el = $(document).find(class_list.join(''));
                item_el.removeClass('active');
                $(this).parents('.accommodation-item-parent').addClass('active');
            }
        });
    }
}

export default CourseAccommodationCheckbox