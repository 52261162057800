class CourseCategory {
    constructor() {
        this.init();
    }

    init() {
        let selectors = document.querySelectorAll('.block-course-cat .i-cat');
        let selectors_box = document.querySelectorAll('.block-course-cat-box');
        let selectors_img_box = document.querySelectorAll('.block-course-cat-img-box');

        selectors.forEach(function (selector) {
            selector.addEventListener('mouseover', function (e) {
                e.preventDefault();
                selectors.forEach(function (item) {
                    item.classList.remove('active');
                });
                this.classList.add('active');
                let el_index = this.getAttribute('data-cat-index');
                let el_content = document.querySelector('[data-cat-box-index="' + el_index + '"]');
                let el_content_img = document.querySelector('[data-cat-img-box-index="' + el_index + '"]');
                if (el_content) {
                    selectors_box.forEach(function (item) {
                        item.classList.remove('active');
                    });
                    el_content.classList.add('active');
                }
                selectors_img_box.forEach(function (item) {
                    item.classList.remove('active');
                });
                if (el_content_img) {
                    el_content_img.classList.add('active');
                }
                window.matchHeight.init();
            })
        });

    }
}

export default CourseCategory;